export const IMAGE_PATHS = {
  // Icons
  MOHAWK_COMMERCIAL_LOGO:
    'https://s7d4.scene7.com/is/image/MohawkResidential/Mohawk_Group_logo_red?fmt=png-alpha',
  MOHAWK_RESIDENTIAL_LOGO:
    'https://s7d4.scene7.com/is/image/MohawkResidential/mohawk_color_logo?fmt=png-alpha',
  MOHAWK_RECOVER_PROGRAM_LOGO:
    'https://s7d4.scene7.com/is/image/MohawkResidential/recover_recycling_program_color_tagline_logo-3?fmt=png-alpha',
  MOHAWK_ROCOVER_LOGO:
    'https://s7d4.scene7.com/is/image/MohawkResidential/recover_all_white_logo?fmt=png-alpha',
  INSTAGRAM_SOCIAL_MEDIA_ICON:
    'https://s7d4.scene7.com/is/image/MohawkResidential/instagram?fmt=png-alpha',
  FACEBOOK_SOCIAL_MEDIA_ICON:
    'https://s7d4.scene7.com/is/image/MohawkResidential/Facebook?fmt=png-alpha',
  TWITTER_SOCIAL_MEDIA_ICON:
    'https://s7d4.scene7.com/is/image/MohawkResidential/twitter?fmt=png-alpha',

  // Images
  COMMERCIAL_ABOUT_BACKGROUND:
    'https://s7d4.scene7.com/is/image/MohawkResidential/black_plastic_pellet_stock_image?scl=4&fmt=webp',
  RESIDENTIAL_ABOUT_BACKGROUND:
    'https://s7d4.scene7.com/is/image/MohawkResidential/black_plastic_pellet_stock_image?scl=4&fmt=webp',

  // Sample images
  CARPET_STOCK:
    'https://s7d4.scene7.com/is/image/MohawkResidential/mhg_recover_graphic_3600x2400_300dpi_01?scl=4&fmt=webp',
  HOME_CARPET:
    'https://s7d4.scene7.com/is/image/MohawkResidential/mhg_recover_graphic_3600x2400_300dpi_00?scl=4&fmt=webp',
  CARPET_ROLL:
    'https://s7d4.scene7.com/is/image/MohawkResidential/mhg_recover_graphic_3600x2400_300dpi_02?scl=4&fmt=webp',
  ROOM_1:
    'https://s7d4.scene7.com/is/image/MohawkResidential/8M044_727_room_00?scl=4&fmt=webp',
  ROOM_2:
    'https://s7d4.scene7.com/is/image/MohawkResidential/32659_02_room_01?scl=4&fmt=webp'
};
