export const REDIRECT_URLS = {
  LEGAL_URL: 'https://mohawkind.com/legal.php',
  TERMS_CONDITION_URL: 'https://mohawkind.com/terms-and-conditions.php',
  VENDOR_URL: 'https://mohawkind.com/vendors.php',
  PRIVACY_URL: 'https://mohawkind.com/privacy-policy.php',
  PATENTS_URL: 'https://www.unilintechnologies.com/en/mohawk',
  INSTAGRAM_URL: 'https://www.instagram.com/mohawkgroup/',
  FACEBOOK_URL: 'https://www.facebook.com/mohawkgroup/',
  TWITTER_URL: 'https://twitter.com/MohawkGroup',
  LINKEDIN_URL: 'https://www.linkedin.com/company/mohawk-group/',
  PINTEREST_URL: 'https://www.pinterest.com/mohawkgroup/'
};
