import React, { FC } from 'react';
import Button from '../Button';
import { Link, generatePath } from 'react-router-dom';
import { ICON_PATHS } from '../../constants/iconPaths';
import { IMAGE_PATHS } from '../../constants/imagePaths';
import './utilityNavigation.scss';
import { AppRoutes } from '../../../routes/routeConstants/appRoutes';
import { useRedirect } from '../../hooks/useRedirect';

interface UtilityNavigationProps {
  openDrawer: () => void;
  isResedentialHeader?: boolean;
}

const UtilityNavigation: FC<UtilityNavigationProps> = (props) => {
  const { openDrawer, isResedentialHeader } = props;

  const { changePath } = useRedirect();

  const logoImage = isResedentialHeader
    ? IMAGE_PATHS.MOHAWK_RESIDENTIAL_LOGO
    : IMAGE_PATHS.MOHAWK_COMMERCIAL_LOGO;

  const reclamationFormUrl = isResedentialHeader
    ? AppRoutes.RESIDENTIAL_RECLAMATION_FORM
    : AppRoutes.COMMERCIAL_RECLAMATION_FORM;

  const landingPageUrl = isResedentialHeader
    ? AppRoutes.RESIDENTIAL_LANDING
    : AppRoutes.COMMERCIAL_LANDING;

  const pageType = isResedentialHeader ? 'residential' : 'commercial';

  return (
    <div className="utility-navigation">
      <div className="utility-navigation__logo">
        <img
          alt="menu-icon"
          onClick={openDrawer}
          className="hamburger-icon"
          src={ICON_PATHS.HAMBURGER_ICON}
          data-testid="hambergur-menu-icon"
        />
        <Link to={landingPageUrl}>
          <img
            alt="logo"
            data-testid="logo"
            className={`logo ${
              isResedentialHeader
                ? 'logo--residential-mobile'
                : 'logo--commercial-mobile'
            }`}
            src={logoImage}
          />
        </Link>
      </div>
      <div className="utility-navigation__links">
        <ul>
          <li>
            <Link
              to={generatePath(AppRoutes.TRACKING_FORM, {
                formType: pageType
              })}
            >
              Track My Status
            </Link>
          </li>
          <li>
            <Link
              to={generatePath(AppRoutes.FAQ, {
                formType: pageType
              })}
            >
              FAQs
            </Link>
          </li>
          <li>
            <Link
              to={generatePath(AppRoutes.CONTACT_US, {
                formType: pageType
              })}
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <Button
          className="utility-navigation__links__quote-btn"
          size="large"
          type="primary"
          onClick={() => changePath(reclamationFormUrl)}
        >
          Start A Quote
          <img
            alt="arrow-icon"
            className="ml-2"
            src={ICON_PATHS.RIGHT_ARROW_ICON}
          />
        </Button>
      </div>
    </div>
  );
};

export default UtilityNavigation;
