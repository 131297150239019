import React from 'react';
import AppRoutes from './routes';
import { AuthProvider } from './context/AuthContext';
import RequireNetwork from './shared/components/HOC/requireNetwork';
import 'antd/dist/antd.css';
import './styles/_main.scss';

const App = () => {
  return (
    <RequireNetwork>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </RequireNetwork>
  );
};

export default App;
