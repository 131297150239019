import React, { FC, useState } from 'react';
import TopNavigation from '../TopNavigation';
import UtilityNavigation from '../UtilityNavigation';
import Sidebar from '../Sidebar';
import './header.scss';

interface HeaderProps {
  isResedentialHeader?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { isResedentialHeader } = props;

  const toggleDrawer = () => {
    setDrawerVisible((prevState) => !prevState);
  };

  return (
    <div className="header">
      <Sidebar
        drawerVisible={drawerVisible}
        closeDrawer={toggleDrawer}
        isResidentialHeader={isResedentialHeader}
      />
      <TopNavigation />
      <UtilityNavigation
        openDrawer={toggleDrawer}
        isResedentialHeader={isResedentialHeader}
      />
    </div>
  );
};

export default Header;
