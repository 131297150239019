export const AppRoutes = {
  APP_COMPONENTS: '/app-component',
  HOME: '/home',
  CUSTOMER_LANDING: '/',
  COMMERCIAL_LANDING: '/commercial',
  COMMERCIAL_RECLAMATION_FORM: '/commercial/reclamation-form',
  RESIDENTIAL_LANDING: '/residential',
  RESIDENTIAL_RECLAMATION_FORM: '/residential/reclamation-form',
  RESIDENTIAL_BMF_RECLAMATION_FORM:
    '/residential/reclamation-form/builder-multifamily',
  RESIDENTIAL_RETAILER_RECLAMATION_FORM:
    '/residential/reclamation-form/retailer',
  TRACKING_FORM: '/:formType/track-form',
  TRACK_STATUS: '/:formType/track-status',
  QUOTE_DECLINE_FORM: '/:formType/decline-quote',
  QUOTE_ACCEPT_FORM: '/:formType/view-quote',
  FAQ: '/:formType/faq',
  CONTACT_US: '/:formType/contact-us'
};
