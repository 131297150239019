import { ICON_PATHS } from 'src/shared/constants/iconPaths';
import { PDF_PATHS } from 'src/shared/constants/pdfPaths';

export const USER_CONSTANTS = {
  EMAIL_ID: 'recover@mohawkind.com',
  PHONE_NUMBER: '1-877-373-2925'
};

export const COMMERCIAL_LANDING_CONSTANTS = {
  ABOUT_DESCRIPTION: `Mohawk Group’s ReCover Recycling Program is an award-winning flooring recycling program that, since 2006, has diverted over 160 million pounds of flooring from landfills. And we don’t just accept Mohawk flooring, we accept flooring from any manufacturer. We do this because we Believe in Better. Life cycle thinking is part of our commitment to product circularity. Product end of life is important to reduce environmental impacts. Recycling flooring helps us keep valuable materials out of landfills and find a second life.  Depending on flooring type and condition, your flooring may be reused or turned into:`,
  HOW_IT_WORKS_DESCRIPTION: `Mohawk Group has a network of recycling partners allowing us to find a partner for any project across the US and Canada.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Fill out the reclamation form on our website.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Gather the flooring for recycling and load it in the provided container. *Flooring type and condition requirements must be met. Please see guideline document for details.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Receive a certificate showing your positive impact on the planet.

When you use ReCover, you do not need to find a recycler and transportation, then deal with the invoicing for each job. Instead, Mohawk Group will manage your recycling through ReCover as a convenient one-stop-shop! If you have questions or need further assistance, email us at <a href="mailto:${USER_CONSTANTS.EMAIL_ID}" class="red-text">${USER_CONSTANTS.EMAIL_ID}</a>, or call us at <a href="tel:${USER_CONSTANTS.PHONE_NUMBER}" class="red-text">${USER_CONSTANTS.PHONE_NUMBER}</a>.`,
  CONTENT_ICONS: [
    { img: ICON_PATHS.CAR_DASHBOARS_ICON, name: 'Car Parts' },
    { img: ICON_PATHS.WASTE_ENERGY, name: 'Waste to Energy' },
    { img: ICON_PATHS.CARPET_ICON, name: 'Carpet Pad' },
    { img: ICON_PATHS.YARN_ICON, name: 'Yarn' },
    { img: ICON_PATHS.PUNCH_BAG_ICON, name: 'Punching Bag' }
  ],
  SUB_TITLE: 'Types of Flooring Accepted:',
  SUB_CONTENT: [
    'Broadloom',
    'Carpet Tile',
    'Pad',
    'LVT',
    'Laminate',
    'Sheet Vinyl',
    'VCT'
  ],
  RESOURCE_CARDS: [
    {
      title: 'Guidelines',
      iconSrc: ICON_PATHS.GUIDELINES_ICON,
      rawIconSrc: ICON_PATHS.DOWNLOAD_ICON,
      onClick: () => window.open(PDF_PATHS.COMMERCIAL_GUIDELINES_PDF, '_blank')
    },
    {
      title: 'For Specifiers',
      iconSrc: ICON_PATHS.SPECIFIERS_ICON,
      rawIconSrc: ICON_PATHS.DOWNLOAD_ICON,
      onClick: () =>
        window.open(PDF_PATHS.COMMERCIAL_FOR_SPECIFIERS_PDF, '_blank')
    },
    {
      title: 'FAQs',
      iconSrc: ICON_PATHS.FAQ_ICON,
      rawIconSrc: ICON_PATHS.DOWNLOAD_ICON,
      onClick: () => window.open(PDF_PATHS.COMMERCIAL_FAQ_PDF, '_blank')
    }
  ]
};

export const RESIDENTIAL_LANDING_CONSTANTS = {
  ABOUT_DESCRIPTION: `Mohawk Flooring’s ReCover Recycling Program is an award-winning flooring recycling program that, since 2006, has diverted over 160 million pounds of flooring from landfills. And we don’t just accept Mohawk flooring, we accept flooring from any manufacturer. Life cycle thinking is part of our commitment to product circularity. Product end of life is important to reduce environmental impacts. Recycling flooring helps us keep valuable materials out of landfills and find a second life. Depending on flooring type and condition, your flooring may be reused or turned into:`,
  HOW_IT_WORKS_HOMEOWNER: `Please reach out to your local flooring retailer or installer and ask about Mohawk’s ReCover program. They may already be active participants, or they may consider joining this initiative. Your retailer will provide comprehensive information on how you can actively contribute to waste diversion during your next flooring purchase. If they do not currently participate in the ReCover program, kindly suggest they contact us at <a href="tel:${USER_CONSTANTS.PHONE_NUMBER}" class="red-text">${USER_CONSTANTS.PHONE_NUMBER}</a> or via email at <a href="mailto:${USER_CONSTANTS.EMAIL_ID}" class="red-text">${USER_CONSTANTS.EMAIL_ID}</a>.`,
  HOW_IT_WORKS_RETAILERS: `Mohawk Flooring has a network of recycling partners allowing us to find a partner for any project across the US and Canada. Our ReCover team will work with you to tailor a program that fits your needs.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Fill out the reclamation form on our website.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Gather the flooring for recycling and load it in the provided container. *Flooring type and condition requirements must be met. Please see guideline document for details.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Receive a certificate showing your positive impact the planet.

When you use ReCover, you do not need to find a recycler and transportation, then deal with the invoicing for each job. Instead, Mohawk Group will manage your recycling through ReCover as a convenient one-stop-shop! If you have questions or need further assistance, email us at <a href="mailto:${USER_CONSTANTS.EMAIL_ID}" class="red-text">${USER_CONSTANTS.EMAIL_ID}</a>, or call us at <a href="tel:${USER_CONSTANTS.PHONE_NUMBER}" class="red-text">${USER_CONSTANTS.PHONE_NUMBER}</a>.`,
  HOW_IT_WORKS_BUILDER_MULTIFAMILY: `Mohawk Flooring has a network of recycling partners allowing us to find a partner for any project across the US and Canada.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Fill out the reclamation form on our website.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Gather the flooring for recycling and load it in the provided container. *Flooring type and condition requirements must be met. Please see guideline document for details.

<img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon" />  Receive a certificate showing your positive impact on the planet.

When you use ReCover, you do not need to find a recycler and transportation, then deal with the invoicing for each job. Instead, Mohawk Group will manage your recycling through ReCover as a convenient one-stop-shop! If you have questions or need further assistance, email us at <a href="mailto:${USER_CONSTANTS.EMAIL_ID}" class="red-text">${USER_CONSTANTS.EMAIL_ID}</a>, or call us at <a href="tel:${USER_CONSTANTS.PHONE_NUMBER}" class="red-text">${USER_CONSTANTS.PHONE_NUMBER}</a>.`,
  CONTENT_ICONS: [
    { img: ICON_PATHS.CAR_DASHBOARS_ICON, name: 'Car Parts' },
    { img: ICON_PATHS.WASTE_ENERGY, name: 'Waste to Energy' },
    { img: ICON_PATHS.CARPET_ICON, name: 'Carpet Pad' },
    { img: ICON_PATHS.YARN_ICON, name: 'Yarn' },
    { img: ICON_PATHS.PUNCH_BAG_ICON, name: 'Punching Bag' }
  ],
  SUB_TITLE: 'Types of Flooring Accepted:',
  SUB_CONTENT: [
    'Carpet',
    'Pad',
    'LVT',
    'SPC',
    'Laminate',
    'RPC',
    'Sheet Vinyl'
  ],
  RESOURCE_CARDS: [
    {
      title: 'Guidelines',
      iconSrc: ICON_PATHS.GUIDELINES_ICON,
      rawIconSrc: ICON_PATHS.DOWNLOAD_ICON,
      onClick: () => window.open(PDF_PATHS.RESIDENTIAL_GUIDELINES_PDF, '_blank')
    },
    {
      title: 'FAQs',
      iconSrc: ICON_PATHS.FAQ_ICON,
      rawIconSrc: ICON_PATHS.DOWNLOAD_ICON,
      onClick: () => window.open(PDF_PATHS.RESIDENTIAL_FAQ_PDF, '_blank')
    }
  ]
};

export const TERMS_AND_CONDITIONS = `<p>1. The ReCover Recycling Program is a service of Mohawk Carpet Distribution, LLC (&ldquo;Mohawk&rdquo;)</p>
<p>2. Pricing is good for 30 days following the Quote. Payment terms are Net 30 days. Mohawk reserves the right to issue a finance charge equal to the lesser of 1 &frac12;% per month or the maximum rate allowed by law on each payment received after its due date. A fifty ($50.00) dollar fee shall be charged to Customer on each check returned due to insufficient funds.</p>
<p>3. Each Quote is based on a 53&rsquo; trailer unless otherwise noted. A 53&rsquo; trailer requires a loading dock or forklift to safely load the flooring. You must inform Mohawk of any special freight needs.&nbsp;</p>
<p>4. In the event that fuel prices materially increase during the 30 day period following the Quote, the price estimate may be subject to a fuel surcharge of up to 5%.</p>
<p>5. Sales tax is not included. Sales tax will be billed at the local applicable rate. Any sales tax exemption certiﬁcate must be submitted applicable prior to Mohawk&rsquo;s invoicing.&nbsp;</p>
<p>6. Holding containers in excess of the agreed time frame will result in additional costs, in Mohawk&rsquo;s discretion.</p>
<p>7. Mohawk will contact you regarding pickup arrangements prior to the project start date. In the event that the container is refused on the agreed upon arrival date, a $500 container refusal fee (per occurrence) will be invoiced.</p>
<p>8. The container shall only be used for disposal of the approved post-consumer ﬂooring. No other waste disposal items will be accepted by Mohawk. Any costs or liabilities incurred by Mohawk due to non-approved materials loaded in the container will be the sole responsibility of the customer.</p>
<p>9. Customer is liable for all costs associated with additional materials not disclosed in the Quote. This includes additional quantities and material types.</p>
<p>10. The flooring must be prepared according to Mohawk&rsquo;s provided guidelines. Flooring not prepared according to such guidelines shall incur additional charges to be invoiced by Mohawk.</p>
<p>11. Customer must obtain a Mohawk account number prior to scheduling a pickup.</p>
<p>12. If the approved post-consumer flooring is not properly stacked on pallets in the container, the third-party carrier may assess a full trailer fee, which may exceed $2,000.00 per occurrence. Customer shall be solely responsible for such a fee which shall be invoiced by Mohawk.</p>
<p>13. If any scrap in the container is unsecured, the container will not be removed from the job site. Customer shall pay any additional storage and freight charges as a result.</p>
<p>14. The recycling company may reject any load which contains non-approved materials or unsecured flooring. Customer shall pay any additional storage and freight charges incurred as a result of such refusal.</p>
<p>15. Mohawk shall not be held liable for any non-conforming material or any charges that arise from non-conforming loads. Any additional costs or liability associated with a non-conforming load will be invoiced to the customer.</p>
<p>16. Please provide Mohawk with images of your ﬂooring at recover@mohawkind.com prior to pick up to make sure the flooring is prepared according to guidelines.</p>
<p>17. Any images so provided to Mohawk may be used for marketing and/or commercial purposes.</p>
<p>18. No liability shall result from Mohawk&rsquo;s delay in performance or nonperformance of this Agreement directly or indirectly caused by fire, explosion, accidents, flood, labor trouble or shortage, act of or authorized by government, inability to obtain suitable material, equipment, fuel, power or transportation, or act of God or arising from other contingencies, happenings, or causes beyond Mohawk&rsquo;s control.</p>
<p>19. The services provided by Mohawk under these Terms and Conditions are provided without warranty, whether express or implied. Mohawk disclaims any and all warranties under these Terms and Conditions.</p>
<p>20. As it relates to these Terms and Conditions, Customer shall comply with all applicable federal, state and local statutes, regulations, rules, ordinances and common laws, including but not limited to environmental law and regulation.</p>
<p>21. Customer will indemnify, defend and hold Mohawk (including its parent, affiliates, subsidiaries, associates, directors, officers, employees and agents) harmless against and from any and all claims, lawsuits, judgments, losses, recalls, civil penalties or actions, costs, liabilities, damages and expenses (&ldquo;Loss&rdquo;) (including but not limited to, attorney&rsquo;s fees) incurred or to be incurred, which may be made or brought against Mohawk by any person, corporation, government, governmental agency, class, or any other entity whatsoever, arising or alleged to have arisen:</p>
<p>&nbsp; &nbsp; (a) out of the death of or injury to any person or damage to any property which resulted or is alleged to have resulted from any acts or omissions of Customer, its employees and agents, contractors, subcontractors and /or any other persons for whose conduct it may be or is alleged to be legally responsible or from the Materials or their use;</p>
<p>&nbsp; &nbsp; (b) out of any infringement of any patent, design, trade name, trademark, copyright, trade secret, or any other right or entitlement of any third party;</p>
<p>&nbsp; &nbsp; (c) out of any environmental, property and/or toxic tort claim, lawsuit, judgment, loss, civil penalty or action;</p>
<p>&nbsp; &nbsp; (d) from Customer&rsquo;s failure to comply with and/or Customer&rsquo;s breach of these Terms and Conditions; and</p>
<p>&nbsp; &nbsp; (e) from the failure of Customer to comply with any applicable state or federal laws. This indemnification duty applies whether the Loss was alleged to have been caused in part by the negligence or fault of Mohawk. In no event shall Mohawk be liable to Customer for any lost revenue or lost profits or any exemplary, special, incidental, indirect, punitive or consequential damages, regardless of whether such damages could have been foreseen or prevented by Mohawk or whether brought under a theory of tort, contract or any other legal basis.</p>
<p>22. Customer shall be solely and independently responsible for the direction, supervision and control of its employees and for any other persons or firms Customer engages to assist in performance. Customer shall maintain adequate safeguards and use reasonable care to protect Mohawk&rsquo;s employees and property. Customer further agrees that it carries and will continue to carry the following insurance with limits adequate to insure against all reasonably foreseeable injury or damage to the persons and property hereinabove set forth for which Customer might be held liable: worker&rsquo;s compensation insurance and commercial general liability insurance.</p>
<p>23. Customer shall maintain as confidential any and all information received by Customer in the course of performance of this Agreement and shall not reveal any such information, except to persons having a strict need therefore in the performance of this Agreement.</p>
<p>24. No terms or conditions, other than those stated herein, and no agreement or understanding in any way modifying the terms and conditions herein shall be binding on Mohawk, unless such modifications are made in writing on Mohawk&rsquo;s stationery and executed by an officer of Mohawk.</p>
<p>25. These Terms and Conditions, related agreements/bids and any amendments hereto are governed by the laws of the State of Georgia, without regard to conflict of laws provisions. The invalidity or unenforceability of a particular provision of this Agreement will not affect the other provisions, and this Agreement will be construed in all respects as if all invalid or unenforceable provisions were omitted. To the fullest extent allowed under applicable law, the parties waive their right to a trial by jury.</p>`;

export const ACKNOWLEDGEMENT_AND_AGREEMENT = `<p>If this proposal meets your approval, please sign this proposal which will also serve as your purchase order.</p>
<p>Should you have any questions, please contact the Mohawk ReCover team at (877) 373-2925 or recover@mohawkind.com. Mohawk looks forward to working with you on this reclamation project.</p>
<p>The undersigned hereby accepts, agrees, and acknowledges to the foregoing proposal under all terms and conditions set forth therein Customer</p>`;

export const COUNTRY_LIST = {
  CANADA: 'Canada',
  USA: 'United States of America'
};

export const FAQ_CONSTANT_COMMERCIAL: { question: string; answer: string }[] = [
  {
    question: 'How does it work?',
    answer: `<div>
    <div>1. Fill out the reclamation form on our website and receive an all-inclusive quote within 3 business days.</div>
    <div>2. You provide us with approval and pick-up date and prepare the flooring for pick-up according to guidelines.</div>
    <div>3. We arrange a container and handle logistics so you don&rsquo;t have to.</div>
    <div>4. You receive a certificate from Mohawk verifying the positive impact your project has on the planet.</div>
    <div>5. You receive an invoice via your Mohawk customer account.</div>
    </div>`
  },
  {
    question: 'How do I obtain a quote?',
    answer: `Head to our website and click “Start A Quote”. Enter all requested information. The ReCover team may follow up to ask for pictures, samples, or other clarification. Standard turnaround time for a quote is within 3 business days.`
  },
  {
    question: 'Is there a fee associated with your recycling services?',
    answer: `Recycling/landfill diversion fees are assessed on a project-by-project basis. Costs are associated with the collection and shipping of used flooring and vary based upon material types, quantities, and recycling facility destination.`
  },
  {
    question: 'What are freight costs and what does that include?',
    answer: `Transportation/hauling costs are calculated per project and are based on specific client requirements. Standard costs include live load pick-up for within a 2 hour window and delivery to recycling site with a 53’ trailer (freight). Please inform the ReCover team of any special freight needs (freight accommodations should be considered if you do not have a loading dock or forklift on site, do not have space for a 53’ trailer, or need a container for longer than 2 hours). Special freight needs can be requested and may be provided depending upon location and may incur additional charges. General contractor or client personnel are responsible for following guidelines for packaging of material for proper preparation and loading for transport.`
  },
  {
    question: 'What transportation containers do you provide?',
    answer: `The standard container we provide is a 53’ trailer for a 2 hour live load. Use of a 53’ trailer requires a loading dock or forklift to load the trailer. If you are unable to meet these requirements, please inform the ReCover team when you request a quote so we can do our best to accommodate your needs. Any special freight needs (such as lift gates, rentals, special truck size, or dumpster) can be requested and may be provided depending upon location and availability at an extra charge.`
  },
  {
    question:
      'Will you provide instructions on how to prepare material for shipping?',
    answer: `Yes, a guidelines sheet will be sent with each quote. Additional requirements may be included per recycling partner guidelines.`
  },
  {
    question: 'What materials do you accept?',
    answer: `We accept all carpet tile, broadloom, carpet pad, LVT, laminate, sheet vinyl, and VCT.`
  },
  {
    question: 'Do you recycle sample books?',
    answer: `If you would like to return or recycle your sample books, please contact your sales representative or email samples_commercial@mohawkind.com.`
  },
  {
    question: 'Will you accept another manufacturer’s flooring?',
    answer: `We divert any manufacturer’s flooring, not just our flooring, from landfills through our network of reclamation partners across North America, so long as it meets the type and condition requirements.`
  },
  {
    question:
      'What if I do not have a Mohawk Account Number? Can I still use the ReCover Recycling Program?',
    answer: `Yes, you can still utilize the ReCover Program. If you do not have a Mohawk Account, you will be provided instructions to establish an account.`
  },
  {
    question: 'Do you provide landfill diversion certificates?',
    answer: `Yes, certificates are sent for each completed project.`
  },
  {
    question: 'Do you provide landfill diversion reporting if needed?',
    answer: `Yes, we can provide total square yards, pounds, and type of diversion method utilized.`
  },
  {
    question: 'What method will be used to divert material from the landfill?',
    answer: `<div>
    <div>
    <div>Depending upon material condition, volume, material type, and reclamation partner, the following landfill diversion options will be utilized:</div>
    <div class="mb-2 mt-2">&nbsp; &nbsp; <img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon-faq" /> Reuse</div>
    <div class="mb-2">&nbsp; &nbsp; <img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon-faq" /> Recycled</div>
    <div class="mb-2">&nbsp; &nbsp; <img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon-faq" /> Waste-to-energy</div>
    </div>
    </div>`
  },
  {
    question: 'What happens to my flooring when it gets recycled?',
    answer: `Depending upon material condition, material type, and recycling partner, materials may be used for: drainage tiles, carpet pad, cement, automotive parts, yarn for clothing and textiles, punching bags, plastic resin, or other plastic extrusion applications.`
  },
  {
    question: 'Who can use ReCover?',
    answer: `ReCover is available to anyone interested in recycling flooring - A&D firms, dealers, installers, general contractors, and retailers.`
  }
];

export const FAQ_CONSTANT_RESIDENTIAL: { question: string; answer: string }[] =
  [
    {
      question: 'What is the ReCover program?',
      answer: `The ReCover program is a service provided by Mohawk Flooring to customers, that facilitates recycling
      of carpet and other flooring materials, in an effort to find a second useful life for flooring while reducing
      the impacts on landfills across the country.`
    },
    {
      question: 'Who can use ReCover?',
      answer: `ReCover is available to anyone interested in recycling flooring - A&D firms, dealers, installers, general
      contractors, and retailers. At this time, ReCover is not directly available to end users / homeowners.`
    },
    {
      question: 'How does the ReCover program work?',
      answer: `<div>1. Fill out the reclamation form on our <a href="http://mohawkrecover.com/residential" target="_blank">website</a> and receive an all-inclusive<br>quote within 3 business days.<br>2. You provide us with approval and pick-up date and prepare the flooring for<br>pick-up according to guidelines.<br>3. We arrange a container and handle logistics so you don&rsquo;t have to.<br>4. You receive a certificate from Mohawk verifying the positive impact your<br>ReCover project has on the planet.<br>5. You receive an invoice via your Mohawk customer account.</div>`
    },
    {
      question: 'How do I obtain a quote?',
      answer: `Head to our <a href="http://mohawkrecover.com/residential" target="_blank">website</a> and click &ldquo;Start A Quote&rdquo; and enter all requested information. The ReCover team may follow up to ask for pictures, samples, or other clarification. Standard turnaround time for a quote is within 3 business days.`
    },
    {
      question: 'Is there a fee associated with your recycling services?',
      answer: `Recycling/landfill diversion fees are assessed on a project-by-project basis. Costs are associated with the
      collection and shipping of used flooring and vary based upon material types, quantities, and recycling
      facility destination.`
    },
    {
      question: 'What are freight costs and what does that include?',
      answer: `Transportation/hauling costs are calculated per project and are based on specific client requirements.
      Standard costs include live load pick-up for within a 2 hour window and delivery to recycling site with a
      53’ trailer (freight). Please inform the ReCover team of any special freight needs (freight
      accommodations should be considered if you do not have a loading dock or forklift on site, do not have
      space for a 53’ trailer, or need a container for longer than 2 hours). Special freight needs can be
      requested and may be provided depending upon location and may incur additional charges. General
      contractor or client personnel are responsible for following guidelines for packaging of material for
      proper preparation and loading for transport.`
    },
    {
      question: 'What transportation containers do you provide?',
      answer: `The standard container we provide is a 53’ trailer for a 2-hour live load. Use of a 53’ trailer requires a
      loading dock or forklift to load the trailer. If you are unable to meet these requirements, please inform
      the ReCover team when you request a quote so we can do our best to accommodate your needs. Any
      special freight needs (such as lift gates, rentals, special truck size, or dumpster) can be requested and
      may be provided depending upon location and availability at an extra charge.`
    },
    {
      question:
        'Will you provide instructions on how to prepare material for shipping?',
      answer: `Yes, a guidelines sheet will be sent with each quote. Additional requirements may be included per
      recycling partner guidelines.`
    },
    {
      question: 'What materials do you accept?',
      answer: `We accept all broadloom carpet, carpet pad, carpet tile, LVT, SPC, laminate, RPC, and sheet vinyl.`
    },
    {
      question: 'Will you accept another manufacturer’s flooring?',
      answer: `We divert any manufacturers flooring, not just our flooring, from landfills through our network of
      reclamation partners across North America, so long as it meets the type and condition requirements.`
    },
    {
      question:
        'What if I do not have a Mohawk Account Number? Can I still use the ReCover Recycling Program?',
      answer: `If you do not have a Mohawk Account, you will be connected with a local Account Rep to begin the
      process of setting up an account. Reclamation can begin upon completion of account creation.`
    },
    {
      question: 'Do you provide landfill diversion certificates?',
      answer: `Yes, certificates are sent for each completed project.`
    },
    {
      question: 'Do you provide landfill diversion reporting if needed?',
      answer: `Yes, we can provide total square yards, pounds, and type of diversion method utilized.`
    },
    {
      question:
        'What method will be used to divert material from the landfill?',
      answer: `<div>
      <div>
      <div>Depending upon material condition, volume, material type, and reclamation partner, the following landfill diversion options will be utilized:</div>
      <div class="mb-2 mt-2">&nbsp; &nbsp; <img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon-faq" /> Reuse</div>
      <div class="mb-2">&nbsp; &nbsp; <img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon-faq" /> Recycled</div>
      <div class="mb-2">&nbsp; &nbsp; <img src=${ICON_PATHS.RIGHT_ARROW_BULLET_ICON} class="content-bullet-icon-faq" /> Waste-to-energy</div>
      </div>
      </div>`
    },
    {
      question: 'What happens to my flooring when it gets recycled?',
      answer: `Depending upon material condition, material type, and recycling partner, materials may be used for:
      drainage tiles, carpet pad, cement, automotive parts, yarn for clothing and textiles, punching bags,
      plastic resin, or other plastic extrusion applications.`
    }
  ];

export const CONTACT_US = {
  PHONE_ONE: '1-877-3RE-CYCL',
  PHONE_TWO: '1-877-373-2925',
  EMAIL: 'recover@mohawkind.com'
};
