import MOHAWK_COMMERCIAL_SVG from 'src/assets/icons/mohawk-group.svg';
import MOHAWK_RESEDENTIAL_SVG from 'src/assets/icons/mohawk-flooring.svg';
import RIGHT_ARROW_ICON from 'src/assets/icons/right-arrow.svg';
import DOWNLOAD_ICON from 'src/assets/icons/download-icon.svg';
import EMAIL_ICON from 'src/assets/icons/email-icon.svg';
import FAQ_ICON from 'src/assets/icons/faq-icon.svg';
import GUIDELINES_ICON from 'src/assets/icons/guidelines-icon.svg';
import SPECIFIERS_ICON from 'src/assets/icons/specifiers-icon.svg';
import HAMBURGER_ICON from 'src/assets/icons/hamburger-menu.svg';
import PHONE_ICON from 'src/assets/icons/phone-icon.svg';
import RECYCLE_GREEN_WORLD from 'src/assets/icons/recycle-96.png';
import RECYCLE_RED_PNG from 'src/assets/icons/recycle.svg';
import CLOSE_ICON from 'src/assets/icons/close-icon.svg';
import CAR_DASHBOARS_ICON from 'src/assets/icons/car-dashboard-icon.svg';
import CAR_DOOR_ICON from 'src/assets/icons/car-door-icon.svg';
import CARPET_ICON from 'src/assets/icons/carpet-icon.svg';
import RECYCLED_ICON from 'src/assets/icons/recycled-icon.svg';
import REUSE_ICON from 'src/assets/icons/reuse-icon.svg';
import WASTE_ENERGY from 'src/assets/icons/waste-to-energy-icon.svg';
import WASTE_ENERGY_BLACK from 'src/assets/icons/waste-to-energy-icon-black.svg';
import USA_FLAG from 'src/assets/icons/usa-flag.svg';
import CANADA_FLAG from 'src/assets/icons/canada-flag.svg';
import CALENDAR_ICON from 'src/assets/icons/calendar-icon.png';
import DOWN_ARROW_ICON from 'src/assets/icons/drop-down-arrow-icon.png';
import RIGHT_ARROW_BULLET_ICON from 'src/assets/icons/right-arrow-bullet-icon.png';
import PUNCH_BAG_ICON from 'src/assets/icons/punching-bag-icon.png';
import YARN_ICON from 'src/assets/icons/yarn-icon.png';
import RESIDENTIAL_CARBON_CERTIFICATE from 'src/assets/icons/ResidentialCarbonCertificate(Both).png';
import COMMERCIAL_CARBON_CERTIFICATE from 'src/assets/icons/CommercialCarbonCertificate(Both).png';
import SUCCESS_ICON from 'src/assets/icons/success-icon.svg';
import TICK_ICON from 'src/assets/icons/status_tick.svg';
import RECOVER_WHITE_LOGO from 'src/assets/icons/recover-logo-white.svg';
import EMAIL_ICON_FAQ from 'src/assets/icons/email-icon-faq.png';
import PHONE_ICON_FAQ from 'src/assets/icons/phone-icon-faq.png';

export const ICON_PATHS = {
  // Icons
  MOHAWK_COMMERCIAL_SVG,
  MOHAWK_RESEDENTIAL_SVG,
  RIGHT_ARROW_ICON,
  DOWNLOAD_ICON,
  EMAIL_ICON,
  FAQ_ICON,
  GUIDELINES_ICON,
  SPECIFIERS_ICON,
  HAMBURGER_ICON,
  PHONE_ICON,
  RECYCLE_GREEN_WORLD,
  RECYCLE_RED_PNG,
  CLOSE_ICON,
  CAR_DASHBOARS_ICON,
  CAR_DOOR_ICON,
  CARPET_ICON,
  RECYCLED_ICON,
  REUSE_ICON,
  WASTE_ENERGY,
  WASTE_ENERGY_BLACK,
  USA_FLAG,
  CANADA_FLAG,
  CALENDAR_ICON,
  DOWN_ARROW_ICON,
  RIGHT_ARROW_BULLET_ICON,
  PUNCH_BAG_ICON,
  YARN_ICON,
  SUCCESS_ICON,
  TICK_ICON,
  RECOVER_WHITE_LOGO,
  EMAIL_ICON_FAQ,
  PHONE_ICON_FAQ,

  // Images
  COMMERCIAL_CARBON_CERTIFICATE,
  RESIDENTIAL_CARBON_CERTIFICATE
};
