import React, { FC } from 'react';
import FooterInfoBar from 'src/shared/components/Footerbar/FooterInfoBar';
import FooterNavBar from 'src/shared/components/Footerbar/FooterNavBar';

interface FooterBarProps {}

const FooterBar: FC<FooterBarProps> = () => {
  return (
    <div>
      <FooterInfoBar />
      <FooterNavBar />
    </div>
  );
};

export default FooterBar;
