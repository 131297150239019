import React, { FC } from 'react';
import { Drawer } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import { ICON_PATHS } from '../../constants/iconPaths';
import Button from '../Button';
import { IMAGE_PATHS } from '../../constants/imagePaths';
import { USER_CONSTANTS } from '../../constants/userConstants';
import { REDIRECT_URLS } from '../../constants/redirectUrls';
import './sidebar.scss';
import { AppRoutes } from '../../../routes/routeConstants/appRoutes';
import { useRedirect } from '../../hooks/useRedirect';

interface SidebarProps {
  drawerVisible: boolean;
  closeDrawer: () => void;
  isResidentialHeader?: boolean;
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { drawerVisible, closeDrawer, isResidentialHeader } = props;
  const { changePath } = useRedirect();
  const reclamationFormUrl = isResidentialHeader
    ? AppRoutes.RESIDENTIAL_RECLAMATION_FORM
    : AppRoutes.COMMERCIAL_RECLAMATION_FORM;

  const formType = isResidentialHeader ? 'residential' : 'commercial';

  const handleQuoteClick = () => {
    closeDrawer();
    changePath(reclamationFormUrl);
  };

  const handleFaqClick = () => {
    closeDrawer();
    changePath(
      generatePath(AppRoutes.FAQ, {
        formType
      })
    );
  };

  const handleContactUsClick = () => {
    closeDrawer();
    changePath(
      generatePath(AppRoutes.CONTACT_US, {
        formType
      })
    );
  };

  const handleTrackStatusClick = () => {
    closeDrawer();
    changePath(
      generatePath(AppRoutes.TRACKING_FORM, {
        formType
      })
    );
  };

  return (
    <Drawer
      className="sidebar"
      placement="left"
      closable={true}
      onClose={closeDrawer}
      open={drawerVisible}
      width="100%"
    >
      <div className="sidebar-body" data-testid="sidebar-body">
        <div className="sidebar-body__menu-links">
          <div className="close-icon">
            <img src={ICON_PATHS.CLOSE_ICON} onClick={closeDrawer} />
          </div>
          <a onClick={handleTrackStatusClick}>Track My Status</a>
          <a onClick={handleFaqClick}>FAQs</a>
          <a onClick={handleContactUsClick}>Contact Us</a>
          <Button
            className="quote-btn"
            type="primary"
            size="large"
            onClick={handleQuoteClick}
          >
            Start A Quote
          </Button>
        </div>
        <div>
          <div className="sidebar-body__contact-links">
            <Link to={`tel:${USER_CONSTANTS.PHONE_NUMBER}`}>
              <img alt="phone-icon" src={ICON_PATHS.PHONE_ICON} />
              {USER_CONSTANTS.PHONE_NUMBER}
            </Link>
            <Link to={`mailto:${USER_CONSTANTS.EMAIL_ID}`}>
              <img alt="email-icon" src={ICON_PATHS.EMAIL_ICON} />
              {USER_CONSTANTS.EMAIL_ID}
            </Link>
          </div>
          <div className="sidebar-body__social-icons">
            <Link to={REDIRECT_URLS.INSTAGRAM_URL} target="_blank">
              <img
                alt="instagram-icon"
                src={IMAGE_PATHS.INSTAGRAM_SOCIAL_MEDIA_ICON}
              />
            </Link>
            <Link to={REDIRECT_URLS.FACEBOOK_URL} target="_blank">
              <img
                alt="facebook-icon"
                src={IMAGE_PATHS.FACEBOOK_SOCIAL_MEDIA_ICON}
              />
            </Link>
            <Link to={REDIRECT_URLS.TWITTER_URL} target="_blank">
              <img
                alt="twitter-icon"
                src={IMAGE_PATHS.TWITTER_SOCIAL_MEDIA_ICON}
              />
            </Link>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
