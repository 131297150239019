import { serializable, alias, primitive } from 'serializr';

export class Attachment {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('key', primitive()))
  key?: string;

  @serializable(alias('url', primitive()))
  url?: string;

  @serializable(alias('filename', primitive()))
  filename?: string;

  @serializable(alias('content', primitive()))
  content?: string;

  @serializable(alias('content_type', primitive()))
  contentType?: string;

  @serializable(alias('type', primitive()))
  type?: string;
}
