import COMMERCIAL_GUIDELINES_PDF from 'src/assets/pdf/ReCover Program Guidelines.pdf';
import COMMERCIAL_FAQ_PDF from 'src/assets/pdf/ReCover_FAQ.pdf';
import COMMERCIAL_FOR_SPECIFIERS_PDF from 'src/assets/pdf/A&D_ReCover_Specifications.pdf';
import COMMERCIAL_HOW_IT_WORKS_PDF from 'src/assets/pdf/ReCover_How To Recover Your Flooring.pdf';
import RESIDENTIAL_HOW_IT_WORKS_PDF from 'src/assets/pdf/ResidentialHowItWorks.pdf';
import RESIDENTIAL_GUIDELINES_PDF from 'src/assets/pdf/ResidentialGuidelines.pdf';
import RESIDENTIAL_FAQ_PDF from 'src/assets/pdf/ResidentialFAQs.pdf';

export const PDF_PATHS = {
  COMMERCIAL_FAQ_PDF,
  COMMERCIAL_FOR_SPECIFIERS_PDF,
  COMMERCIAL_GUIDELINES_PDF,
  COMMERCIAL_HOW_IT_WORKS_PDF,
  RESIDENTIAL_HOW_IT_WORKS_PDF,
  RESIDENTIAL_GUIDELINES_PDF,
  RESIDENTIAL_FAQ_PDF
};
