import React, { FC } from 'react';
import './footer-info-bar.scss';
import { ICON_PATHS } from 'src/shared/constants/iconPaths';

interface FooterInfoBarProps {}

const FooterInfoBar: FC<FooterInfoBarProps> = () => {
  return (
    <div className="footer-info-bar">
      <div className="footer-info-bar__content">
        <img
          src={ICON_PATHS.RECOVER_WHITE_LOGO}
          className="footer-info-bar__content__logo"
        />
        <span className="footer-info-bar__content__contact">
          <div className="footer-info-bar__content__contact__help">
            Need Help?
          </div>
          <a
            href="mailto:recover@mohawkind.com"
            className="footer-info-bar__content__contact__mail"
          >
            <img
              src={ICON_PATHS.EMAIL_ICON}
              className="footer-info-bar__content__contact__mail__icon"
            />
            recover@mohawkind.com
          </a>
        </span>
      </div>
    </div>
  );
};

export default FooterInfoBar;
