import React, { FC } from 'react';
import { ICON_PATHS } from 'src/shared/constants/iconPaths';
import { Link } from 'react-router-dom';
import { IMAGE_PATHS } from 'src/shared/constants/imagePaths';
import { USER_CONSTANTS } from 'src/shared/constants/userConstants';
import { REDIRECT_URLS } from 'src/shared/constants/redirectUrls';
import './topNavigation.scss';

interface TopNavigationProps {}

const TopNavigation: FC<TopNavigationProps> = () => {
  return (
    <div className="top-navigation">
      <div>
        <span>
          <Link
            className="top-navigation__contact"
            to={`tel:${USER_CONSTANTS.PHONE_NUMBER}`}
          >
            <img data-testid="phone-icon" src={ICON_PATHS.PHONE_ICON} />
            <span className="top-navigation__contact--no-text">
              {USER_CONSTANTS.PHONE_NUMBER}
            </span>
          </Link>
        </span>
        <span>
          <Link
            className="top-navigation__contact"
            to={`mailto:${USER_CONSTANTS.EMAIL_ID}`}
          >
            <img data-testid="email-icon" src={ICON_PATHS.EMAIL_ICON} />
            <span className="top-navigation__contact--no-text">
              {USER_CONSTANTS.EMAIL_ID}
            </span>
          </Link>
        </span>
      </div>
      <div className="top-navigation__social-media">
        <Link to={REDIRECT_URLS.INSTAGRAM_URL} target="_blank">
          <img
            data-testid="instagram-icon"
            src={IMAGE_PATHS.INSTAGRAM_SOCIAL_MEDIA_ICON}
          />
        </Link>
        <Link to={REDIRECT_URLS.FACEBOOK_URL} target="_blank">
          <img
            data-testid="facebook-icon"
            src={IMAGE_PATHS.FACEBOOK_SOCIAL_MEDIA_ICON}
          />
        </Link>
        <Link to={REDIRECT_URLS.TWITTER_URL} target="_blank">
          <img
            data-testid="twitter-icon"
            src={IMAGE_PATHS.TWITTER_SOCIAL_MEDIA_ICON}
          />
        </Link>
      </div>
    </div>
  );
};

export default TopNavigation;
