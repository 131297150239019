import { useNavigate, useLocation } from 'react-router-dom';

export const useRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const changePath = (url: string) => {
    if (location.pathname !== url) {
      navigate(url);
    }
  };

  return {
    changePath
  };
};
