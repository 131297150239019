import React, { FC } from 'react';
import './footer-nav-bar.scss';
import { Link } from 'react-router-dom';
import { REDIRECT_URLS } from 'src/shared/constants/redirectUrls';

interface FooterNavBarProps {}

const FooterNavBar: FC<FooterNavBarProps> = () => {
  return (
    <div className="footer-navigation-bar">
      <div className="footer-navigation-bar__content">
        <div className="footer-navigation-bar__content__claims">
          <span className="footer-navigation-bar__content__claims__rights">
            Mohawk Industries © Copyright {new Date().getFullYear()}. All
            Rights Reserved.
          </span>
        </div>
        <div className="footer-navigation-bar__content__documents">
          <span className="footer-navigation-bar__content__documents__holder">
            <Link to={REDIRECT_URLS.LEGAL_URL} data-test-id="legal">
              Legal
            </Link>
            <span className="footer-navigation-bar__content__documents__holder__divider"></span>
            <Link to={REDIRECT_URLS.TERMS_CONDITION_URL} data-test-id="terms">
              Terms and Conditions
            </Link>
            <span className="footer-navigation-bar__content__documents__holder__divider"></span>
            <Link to={REDIRECT_URLS.VENDOR_URL} data-test-id="vendor">
              Vendors
            </Link>
            <span className="footer-navigation-bar__content__documents__holder__part-divider"></span>
          </span>
          <span className="footer-navigation-bar__content__documents__holder">
            <Link to={REDIRECT_URLS.PRIVACY_URL} data-test-id="privacy">
              Privacy Policy
            </Link>
            <span className="footer-navigation-bar__content__documents__holder__divider"></span>
            <Link to={REDIRECT_URLS.PATENTS_URL} data-test-id="patents">
              Patents
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FooterNavBar;
