import React, { lazy, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { RouterProps } from '../shared/types/route.type';
import { AppRoutes } from './routeConstants/appRoutes';
import Spinner from '../shared/components/Spinner';
import DefaultLayout from '../shared/layout/DefaultLayout';
import { ReclamationFormTypes } from '../enums/reclamationFormTypes.enum';

const ResidentialLandingPage = lazy(
  () => import('../views/ResidentialLandingPage')
);
const CommercialLandingPage = lazy(
  () => import('../views/CommercialLandingPage')
);
const LandingPage = lazy(() => import('../views/LandingPage'));
const AppComponents = lazy(() => import('../views/AppComponents'));
const CommercialReclamationForm = lazy(
  () => import('../views/CommercialReclamationForm')
);
const ResidentialReclamationForm = lazy(
  () => import('../views/ResidentialReclamationForm')
);
const TrackingComponent = lazy(() => import('../views/Tracking/TrackingForm'));
const TrackingDetailsComponent = lazy(
  () => import('../views/Tracking/TrackingDetails')
);
const DeclineQuoteForm = lazy(() => import('../views/QuoteDecline'));
const QuoteAcceptForm = lazy(() => import('../views/QuoteAcceptForm'));
const Faq = lazy(() => import('../views/Faq'));
const Contact = lazy(() => import('../views/Contact'));

export const appHistory = createBrowserHistory();
const AppRouter = () => {
  const routes: RouterProps[] = [
    {
      path: AppRoutes.RESIDENTIAL_LANDING,
      component: (
        <DefaultLayout isResidentialHeader={true}>
          <ResidentialLandingPage />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.COMMERCIAL_LANDING,
      component: (
        <DefaultLayout isResidentialHeader={false}>
          <CommercialLandingPage />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.CUSTOMER_LANDING,
      component: <LandingPage />
    },
    {
      path: AppRoutes.COMMERCIAL_RECLAMATION_FORM,
      component: (
        <DefaultLayout isResidentialHeader={false}>
          <CommercialReclamationForm />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.RESIDENTIAL_RECLAMATION_FORM,
      component: (
        <DefaultLayout isResidentialHeader={true}>
          <ResidentialReclamationForm />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.RESIDENTIAL_BMF_RECLAMATION_FORM,
      component: (
        <DefaultLayout isResidentialHeader={true}>
          <ResidentialReclamationForm
            formType={ReclamationFormTypes.BUILDER_MULTIFAMILY}
          />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.RESIDENTIAL_RETAILER_RECLAMATION_FORM,
      component: (
        <DefaultLayout isResidentialHeader={true}>
          <ResidentialReclamationForm
            formType={ReclamationFormTypes.RETAILER}
          />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.TRACKING_FORM,
      component: (
        <DefaultLayout>
          <TrackingComponent />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.TRACK_STATUS,
      component: (
        <DefaultLayout>
          <TrackingDetailsComponent />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.QUOTE_DECLINE_FORM,
      component: (
        <DefaultLayout>
          <DeclineQuoteForm />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.QUOTE_ACCEPT_FORM,
      component: (
        <DefaultLayout>
          <QuoteAcceptForm />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.FAQ,
      component: (
        <DefaultLayout>
          <Faq />
        </DefaultLayout>
      )
    },
    {
      path: AppRoutes.CONTACT_US,
      component: (
        <DefaultLayout>
          <Contact />
        </DefaultLayout>
      )
    }
  ];

  if (process.env.REACT_APP_UNDER_DEVELOPMENT) {
    routes.push({
      path: AppRoutes.APP_COMPONENTS,
      component: <AppComponents />
    });
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {routes?.map((route, index) => {
            return (
              <Route
                key={index}
                path={route?.path}
                element={
                  <Suspense
                    fallback={
                      <Spinner
                        isLoadingOutlined={true}
                        tip="Please wait"
                        size="large"
                        color="secondary"
                        theme="light"
                        fullscreen={true}
                      ></Spinner>
                    }
                  >
                    {route?.component}
                  </Suspense>
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
