import React, { FC, ReactNode } from 'react';
import { Layout } from 'antd';
import Header from 'src/shared/components/Header';
import FooterBar from 'src/shared/components/Footerbar';
import './defaultLayout.scss';
import { useParams } from 'react-router-dom';
import { FormTypeEnum } from '../../../enums/formType.enum';

interface DefaultLayoutProps {
  children: ReactNode;
  isResidentialHeader?: boolean;
}
const { Header: LayoutHeader, Content, Footer } = Layout;

const DefaultLayout: FC<DefaultLayoutProps> = (props) => {
  const { formType } = useParams();
  const {
    children,
    isResidentialHeader = formType === FormTypeEnum.RESIDENTIAL
  } = props;

  return (
    <Layout className="default-layout">
      <LayoutHeader data-test-id="header">
        <Header isResedentialHeader={isResidentialHeader} />
      </LayoutHeader>
      <Content data-test-id="content">{children}</Content>
      <Footer data-test-id="footer-bar">
        <FooterBar />
      </Footer>
    </Layout>
  );
};

export default DefaultLayout;
