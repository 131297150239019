import { serializable, alias, primitive, object } from 'serializr';
import { Attachment } from '../Attachment/attachment.model';

export class User {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('first_name', primitive()))
  firstName?: string;

  @serializable(alias('last_name', primitive()))
  lastName?: string;

  @serializable(alias('email', primitive()))
  email?: string;

  @serializable(alias('phone_number', primitive()))
  phoneNumber?: string;

  @serializable(alias('phone_code', primitive()))
  phoneCode?: string;

  phoneNumberWithCode?: string = '1';

  @serializable(alias('code', primitive()))
  code?: string;

  @serializable(alias('role', primitive()))
  role?: string;

  @serializable(alias('is_active', primitive()))
  isActive?: boolean;

  @serializable(alias('photo', object(Attachment)))
  photo?: Attachment;
}
